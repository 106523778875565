const download={
  methods: {
      async downloadItem(file) {
        this.downloadFile(file)
      },
      async downloadFile(file){
        this.$store
        .dispatch("client/DownloadFile", file)
        .then((response) => {
          const blob = new Blob([response.data], { type: file.Extension });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.Name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((ex) => {
          if (ex.response) {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex.response.data}`
            );
          } else {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex}`
            );
          }
        });
      },
      async downloadFiles(fileData, fileList, category) {
        try {
          if (fileData){
            for (const data of fileData) {
              if (data.file) {
                const file = data.file;
                if(typeof file.upload != "undefined" && file.upload != null){
                    const downloadLink = URL.createObjectURL(file);
                    const link = document.createElement("a");
                    link.href = downloadLink;
                    link.download = file.name;
                    link.click();
                    URL.revokeObjectURL(downloadLink);
                }
                else{
                  fileList.forEach(item => {
                    if(item.Category == category && item.Name == file.name){
                      this.downloadFile(item);
                    }
                  });
                }
              }
            }
          }
        } catch (ex) {
            console.error("Error while downloading file:", ex);
            this.$store.dispatch(
              "progress/setErrorMessage",
              "Error while downloading file. Please try again."
            );
        }
    },
  }
}
export default download;