const DirectorApproval = "Director Approval", FinanceDirectorApproval = "Finance Director Approval", MrApproval = "MR Approval", 
VendorValidation = "Vendor Validation", MdaManagerApproval = "MDA Manager Approval", AccountingApproval = "Accounting Approval", 
CfoApproval = "CFO Approval", FinancialSystemApproval = "Financial Systems Completes", Final = "Completed", Canceled = "Rejected", VendorValidationRejected = "Vendor Validation Rejected";

const AllStates=[
    DirectorApproval,
    FinanceDirectorApproval,
    MrApproval,
    VendorValidation,
    MdaManagerApproval,
    AccountingApproval,
    CfoApproval,
    FinancialSystemApproval, 
    Final,
    Canceled,
    VendorValidationRejected,
]

const PendingRequestStates=[
    DirectorApproval,
    FinanceDirectorApproval,
    MrApproval,
    VendorValidation,
    MdaManagerApproval,
    AccountingApproval,
    CfoApproval,
    FinancialSystemApproval, 
]

const PendingCompletionStates=[
    FinancialSystemApproval,
]
export {  DirectorApproval, FinanceDirectorApproval, MrApproval, VendorValidation, MdaManagerApproval, AccountingApproval, 
    CfoApproval, FinancialSystemApproval, VendorValidationRejected, Final, Canceled, AllStates,PendingCompletionStates,PendingRequestStates };
